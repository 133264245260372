<template>
  <div class="how">
  	<Nav />
  	<Breadcrumbs :items="crumbs"/>
    <div class="how__main">
    	<h1 class="ml-3 mt-3">Contact Us</h1>
			<hr>
			<div class="flex flex-wrap">
        <div class="flex__col1">
        	<h4>
      			Medea Inc. dba M-Caliber
      		</h4>
      		<!-- <p>State of California Contract Number: 1-20-65-32S</p> -->
      		<address>
      			5653 Stoneridge Drive, Suite 119 <br>
      			Pleasanton, CA 94588
      		</address>
      		<p>Office Phone: (925) 425-9282</p>
      		<p>Fax: 866-336-8969</p>
          <p>Email: info@m-caliber.com</p>
      		<br>

      		<!-- <p><strong>Contract Manager:</strong></p>
      		<p>Michael Laidlaw</p>
      		<p>Executive V.P. & General Counsel</p>
      		<p>Office Phone: (925) 425-9282</p>
      		<p>Cell Phone: (916) 769-6210</p>
      		<p>Email: michael@medeamp.com</p> -->
        </div>
        <div class="flex__col2">
        	<!-- <h3>
      			Customer Service Information
      		</h3>
      		<p><strong>Regular Business Hours:</strong></p>
      		<p>Gina Moretti</p>
      		<p>Customer Service Manager</p>
      		<p>Office Phone: (925) 425-9282</p>
      		<p>Fax: (866) 425-9282</p>
      		<p>Email: info@m-caliber.com</p>
      		<br>
      		<p><strong>After Hours:</strong></p>
      		<p>Brandon Laidlaw</p>
      		<p>Chief Executive Officer</p>
      		<p>Cell Phone: (925) 872-1000</p>
      		<p>Office Phone: (925) 425-9282</p>
      		<p>Fax: 866-336-8969</p>
      		<p>Email: brandon@medeamp.com</p> -->
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: 'contact',
  computed: {
    ...mapState(['currentUser', 'userProfile', 'customerProfile']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Home", to: { name: "home"}}
	    let step2 = { title: "Contact Us", to: false}
      crumbsArray.push(step1)
      crumbsArray.push(step2)
      return crumbsArray
    },
  },
  components: {
  	Nav,
  	Footer,
  	Breadcrumbs
  }
}
</script>